<template>
  <div id="app" class="bg-slate-800 w-full min-h-screen text-slate-100" >
    <div>
      <ResponsiveNavigation/>
    </div>
  </div>

</template>

<script>
import ResponsiveNavigation from "@/components/ResponsiveNavigation";
export default {
  components: {
    ResponsiveNavigation
  }
}
</script>

<style>
</style>

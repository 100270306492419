<template>
  <div>
 <h1>Dude... Where's My Page?!?</h1>
  <p>This page you're looking for isn't here! Keep looking!</p>
  </div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>
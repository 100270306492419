<template>


  <div class="p-9 max-w-2xl content-center place-content-stretch ">
    <p class="py-2 text-2xl">Faster Stronger Media Tech - Deletion of Data</p>
    <p class="py-5">If you wish to have your data deleted, please contact <a href="mailto:support@fasterstrongermedia.com">support@fasterstrongermedia.com</a> and we will delete your email, username, and game data.</p>
  </div>
</template>

<script>
export default {
  name: "DeleteData"
}
</script>

<style scoped>

</style>

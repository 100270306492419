<!--./src/components/NavBar.vue -->
<template>
  <nav class="flex items-center justify-between flex-wrap bg-slate-900 p-6">
    <div class="flex items-center flex-shrink-0 text-white mr-6">
      <span class="font-semibold text-xl tracking-tight"><a href="#/">Faster Stronger Media and Tech</a></span>
    </div>
    <div class="block lg:hidden">
      <button class="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white">
        <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
      </button>
    </div>
    <div class="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
      <div class="text-sm lg:flex-grow">
        <a href="#/privacy" class="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
          Privacy Policy
        </a>
        <a href="#/terms" class="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
          Terms of Service
        </a>
      </div>
    </div>
  </nav>
  <component :is="currentView" />

  <nav class="fixed bottom-0 inset-x-0 flex justify-between text-sm uppercase font-mono bg-slate-900 p-6">
    <div class="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
      <div class="text-sm lg:flex-grow">
        © Fasterstrongermedia.com - All rights reserved.
      </div>
    </div>
  </nav>
</template>



<script>
import HomePage from "@/components/HomePage";
import PrivacyPolicy from './PrivacyPolicy.vue'
import TermsOfService from './TermsOfService.vue'
import NotFound from "@/components/NotFound";
import DeleteData from "@/components/DeleteData.vue";

const routes = {
  '/': HomePage,
  '/privacy':PrivacyPolicy,
  '/terms':TermsOfService,
  '/delete-data': DeleteData
}
export default {
  name: "NavBar",data() {
    return {
      currentPath: window.location.hash
    }
  },
  computed: {
    currentView() {
      return routes[this.currentPath.slice(1) || '/'] || NotFound
    }
  },
  mounted() {
    window.addEventListener('hashchange', () => {
      this.currentPath = window.location.hash
    })
  }

};
</script>
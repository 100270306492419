<template>
  <div class="p-9 max-w-2xl content-center place-content-stretch ">
    <div class="border-solid border-2 border-sky-500">
      <img src="@/assets/images/lightbulb.jpg"  />
    </div>
    <div class="p-10">
  <p>
    Hey there! Faster Stronger Media Tech, LLC is our personal mobile and web app development company.
    Faster Stronger Media Tech, LLC is dedicated to creating engaging mobile experiences that can help anyone get through their day just a little easier. We’ve been working on a mobile application for
    people in unique (and sometimes downright ridiculous) situations, and are proud to present
    “Cluster Bingo”. Cluster Bingo is an app made for anyone and everyone who’s dealt with a Cluster
    B person in their life. What’s Cluster B, you ask? Check out the information we’ve provided on this page.
    And maybe—just maybe—try out the app!
  </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePage",
  data() {
    return {
      img: "assets/images/lightbulb.jpg"
    }
  }
}
</script>

<style scoped>

</style>